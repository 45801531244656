import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { addMedlist } from "../../actions/medlist";

const AddMedlist = (props) => {
    const [title, setTitle] = useState("");
    const [Text, setText] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    //onchange input
    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "title") {
            setTitle(value.trimStart());
        }
    };

    //add new medlist
    const handleSubmit = () => {
        if (!title) {
            toast.error("Please enter title");
            return;
        }
        else if (!Text) {
            toast.error("Please enter content ");
            return;
        }
        let params = {
            token: props.auth.token,
            name: title,
            description: Text,
            created_by: "admin"
        }
        setIsLoading(true);
        props.addMedlist(params, res => {
            setIsLoading(false);
            if (res.status) {
                toast.success(res.message);
                props.refreshMedlist();
                props.closeModal()

            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setIsLoading(false);
                toast.error("Something went wrong!");
            })

    };

    useEffect(() => {
        //
    }, [])


    return (
        <>
            <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >

                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="inputFirstName">Title</label>
                    <input className="form-control" id="inputName" type="text" value={title} placeholder="title"
                        name="title" required="" onChange={(e) => { onChangeInput(e, 'title') }} />
                </div>
                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="inputFirstName">Content</label>
                    <CKEditor
                        name="content"
                        id="inputName"
                        className="ckp"
                        placeholder="content"
                        config={{
                            removePlugins: ['MediaEmbed']
                          }}
                        editor={ClassicEditor}
                        data={Text}
                        onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            //console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData()
                            setText(data);
                        }

                        }
                    />
                </div>
                <div className="form-group text-center col-md-12">
                    <Button variant="secondary" onClick={props.closeModal}>Close</Button>
                    &nbsp;&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() => { handleSubmit() }}>
                        {isLoading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Submit</span>
                    </button>
                </div>
            </form>

        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    addMedlist: bindActionCreators(addMedlist, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddMedlist);