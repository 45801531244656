import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import Select from 'react-select';
import { addResources, uploadPhoto } from '../../actions/resources';
import UploadIconImage from "../../public/images/upload-icon.svg";
import groupImage from "../../public/images/Group 10825.svg";
import { MultiSelect } from "react-multi-select-component";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const AddResources = (props) => {
    const allTags = props.tags;
    const allcategories = props.categories;
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [image, setImage] = useState("");
    const [tagsToOptions, setConditionsToOptions] = useState(allTags ? allTags?.map(allTags => allTags) : []);
    const [categoriesToOptions, setCategoriesToOptions] = useState(allcategories ? allcategories?.map(allcategories => allcategories) : []);
    const [selectedTags, setSelectedTags] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingImg, setIsLoadingImg] = useState(false);

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "title") {
            setTitle(value.trimStart());
        }
        else if (fieldName === "Category") {
            setCategoryId(value.trimStart());
        }
    };




    //const selectedConditionId = selectedConditions?.map(item => item.value);
    const selectedTagId = selectedTags && selectedTags.length > 0 ? selectedTags.map(item => item.value) : [];
    const userId = props.auth.userProfile && props.auth.userProfile.user_profiles && props.auth.userProfile.user_profiles.user_id ? props.auth.userProfile.user_profiles.user_id : "";

    //add new resources
    const handleSubmit = () => {
        // if (!image) {
        //     toast.error("Please select image");
        //     return;
        // }
        if (!title) {
            toast.error("Please enter title");
            return;
        }
        else if (!categoryId) {
            toast.error("Please select category");
            return;
        }
        else if (selectedTags.length <= 0) {
            toast.error('Please select atleast one tag.');
            return false;
        }
        else if (!content) {
            toast.error("Please enter content ");
            return;
        }

        let params = {
            token: props.auth.token,
            title: title,
            category_id: categoryId,
            content: content,
            // thumbnail_url: image,
            tags: selectedTagId,
            user_id: userId.toString(),
        }
        //console.log("params",params);
        setIsLoading(true);
        props.addResources(params, res => {
            setIsLoading(false);
            if (res.status) {
                toast.success(res.message);
                props.refreshResources();
                props.closeModal();

            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setIsLoading(false);
                toast.error("Something went wrong!");
            })
    };

    const onChangePicture = e => {
        const imageFile = e.target.files[0];
        if (!imageFile) {
            toast.error("Please select an image.");
            return false;
        }
        else if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
            toast.error("Please select a valid image.");
            return false;
        }

        let params = {
            file: imageFile
        }

        setIsLoadingImg(true);
        props.uploadPhoto(params, res => {
            setIsLoadingImg(false);
            if (res.status) {
                let picUrl = res.data.post_photo;
                setImage(picUrl);
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setIsLoadingImg(false);
                toast.error("Something went wrong!");
            });

    };

    const customMultiSelectItemRenderer = ({ checked, option, onClick, disabled }) => {
        return (
            <div className={`item-renderer ${disabled ? "disabled" : ""}`}>
                <input
                    type="checkbox"
                    onChange={onClick}
                    checked={checked}
                    tabIndex={-1}
                    disabled={disabled}
                />
                <span>{option.label}</span>
            </div>

        );
    };

    useEffect(() => {
        //
    }, [])


    return (
        <>
            <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
                {/* <div className="form-group mt-3 mb-3 col-md-12  text-center">
                    <div className="profile_upload">
                        {isLoadingImg ?
                            <div className="spinner-border text-success mt-3 loader_" role="status" >
                                <span className="sr-only" >Loading...</span>
                            </div>
                            :
                            <div className="inputfile-box">
                                <input type="file" id="file" accept="image/*" className="form-control d-none" onChange={(e) => { onChangePicture(e) }} required="" />
                                <label htmlFor="file">
                                    <span className="file-button">
                                        <img src={image ? image : groupImage} className="upload_src" alt=' ' />
                                        <img src={UploadIconImage} className="upload_icon" alt=' ' />
                                    </span>
                                </label>
                            </div>
                        }
                    </div>
                </div> */}
                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="inputFirstName">Title</label>
                    <input className="form-control" id="inputName" type="text" value={title} placeholder="Title"
                        name="title" required="" onChange={(e) => { onChangeInput(e, 'title') }} />
                </div>

                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="">Category</label>

                    <select className="form-control cust_spc" aria-label="Default select example" onChange={(e) => { onChangeInput(e, 'Category') }} value={categoryId}>
                        <option value="">Select Category</option>
                        {categoriesToOptions && categoriesToOptions.length > 0 && categoriesToOptions.map((frequency, index) => {
                            return <option value={frequency.value} key={index}>{frequency.label}</option>
                        })}
                    </select>
                </div>

                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="">Tags</label>
                    {/* <MultiSelect
                        className="react-multiselect"
                        options={tagsToOptions}
                        value={selectedTags}
                        onChange={setSelectedTags}
                        labelledBy="Select"
                        ItemRenderer={customMultiSelectItemRenderer}
                    //valueRenderer={customMultiSelectValueRenderer}
                    /> */}
                    <Select
                        isMulti
                        name="tags"
                        options={tagsToOptions}
                        value={selectedTags}
                        onChange={setSelectedTags}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Tags"
                    />

                </div>
                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="inputFirstName">Content</label>
                    {/* <input className="form-control" id="inputName" type="text" value={content} placeholder="content"
                        name="content" required="" onChange={(e) => { onChangeInput(e, 'content') }} /> */}
                    <CKEditor
                        name="content"
                        id="inputName"
                        className="ckp"
                        placeholder="content"
                        editor={ClassicEditor}
                        data={content}
                        // onReady={(editor) => {
                        //     // You can store the "editor" and use when it is needed.
                        //     console.log("Editor is ready to use!", editor);
                        // }}
                        onChange={(event, editor) => {
                            const data = editor.getData()
                            setContent(data);
                            // console.log({ event, editor, data }, 'editor');
                        }
                        }
                    />
                </div>
                <div className="form-group text-center col-md-12 mt-3 mb-3">
                    <button className="btn btn-secondary" type="button" onClick={() => { props.closeModal() }}>
                        <span>Close</span>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={isLoading} type="button" onClick={() => { handleSubmit() }}>
                        {isLoading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Submit</span>
                    </button>
                </div>
            </form>

        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    addResources: bindActionCreators(addResources, dispatch),
    uploadPhoto: bindActionCreators(uploadPhoto, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddResources);