import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { getAllUsers, getUserDetails } from '../../actions/user';
import moment from 'moment'
import View from './view';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CustomModal from "../../components/shared/CustomModal";
import PaginationSetting from '../../constants/pagination';


const User = (props) => {
    document.title = "Users";
    const [pagination, setPagination] = useState(PaginationSetting)
    const [users, setUsers] = useState([])
    const [selectedUserId, setSelectedUserId] = useState([])
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [page, setPage] = useState(1);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const getUsers = (clearSearch = false) => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage
        }

        if (!clearSearch && searchInput && searchInput != '') {
            params = { ...params, search: searchInput, page: 1, };
        }

        setLoading(true);
        props.getAllUsers(params, (res) => {
            setLoading(false);
            if (res.statusCode == 200 || res.statusCode == 201) {
                setUsers(res.data.users.users)
                setPagination((prevState) => ({...prevState, totalPages: res.data.users.total_pages, totalRecords: res.data.users.total}));

            }
            else {
                setUsers([]);
                setPagination((prevState) => ({...prevState, totalPages: 0, totalRecords: 0}));

                if(res.statusCode !== 404){
                    toast.error(res.message);
                } 
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    const handleOnCloseModal = () => {
        setModalDetail({show: false, title: ''}); 
        setKey(Math.random());
    }

    const viewUserDetail = (userId) => {
        setSelectedUserId(userId);

        setTimeout(() => {
            setModalDetail({show: true, title: 'User Details'}); 
            setKey(Math.random());
        }, 200);
    }

    const onChangeSearchInput = (searchValue, isClearSearch = false) => {
        setSearchInput(searchValue);
    }

    useEffect(() => {
        getUsers();
    }, [page,searchInput])

    return (
        <>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12 col-md-6">
                                <h1 className="m-0">User</h1>
                            </div>
                            <div className="col-sm-12 col-md-6 text-end">
                                <form className='inputSearchForm'>
                                    <div className='input-group me-2'>
                                        <input type="text" className="form-control rounded" onChange={(e) => onChangeSearchInput(e.target.value)} placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                        {/* <button className='inputClose' onClick={() => {onChangeSearchInput(""); getUsers(true)}} type="reset"> <i className="las la-times"></i> </button> */}
                                    </div>
                                    <button className='searchBtn' type="button" onClick={() => {return searchInput ? getUsers() : ''}}>Search</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Main content */}
                <section className="content pb-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    {loading ?
                                        <div className="row p-5 mt-5 mb-5">
                                            <div className="col-12 text-center">
                                                <div className="spinner-grow text-success" role="status"></div>
                                            </div>
                                        </div>
                                    :
                                        <>
                                            <table style={{ width: '100%' }} className="table table-striped">
                                                <thead style={{ fontSize: '15px' }}>
                                                    <tr>
                                                    <th style={{ borderTop: '0px' }} className='text-center'>Sr. No.</th>
                                                    <th style={{ borderTop: '0px' }}>First Name</th>
                                                    <th style={{ borderTop: '0px' }}>Last Name</th>
                                                    <th style={{ borderTop: '0px' }}>Phone No</th>
                                                    <th style={{ borderTop: '0px' }}>Email</th>
                                                    <th style={{ borderTop: '0px' }}>Role</th>
                                                    <th style={{ borderTop: '0px' }}>Created At</th>
                                                    <th style={{ borderTop: '0px' }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ fontSize: '15px' }}>
                                                    {users && users.length > 0 ? 
                                                            <>
                                                                {users.map((value, index) => 
                                                                    <tr key={index} >
                                                                        <td className='text-center'>{(page - 1) * pagination.recordsPerPage + index + 1}</td>
                                                                        <td>{value.user_profiles.firstname ? value.user_profiles.firstname : "--"}</td>
                                                                        <td>{value.user_profiles.lastname ? value.user_profiles.lastname : "--"}</td>
                                                                        <td>{value.user_profiles.phone_no ? value.user_profiles.phone_no : "--"}</td>
                                                                        <td>{value.email ? value.email : "--"}</td>
                                                                        <td>{value.user_roles.role.name ? value.user_roles.role.name : "" }</td>
                                                                        <td>{moment(value.created_at ? value.created_at : "--").format("YYYY/MM/DD")}</td>
                                                                        <td>
                                                                            <button 
                                                                                className='btn btn-sm btn-del-key internal mr-2' 
                                                                                onClick={() => { viewUserDetail(value.id)}}
                                                                                style={{ background: '#17a2b8', color: 'white' }}
                                                                            >
                                                                                <span><i className='fa fa-eye'></i>&nbsp;</span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    )
                                                                }
                                                            </>
                                                        :
                                                            <tr>
                                                                <td colSpan='7' style={{ textAlign: 'center' }}>No Data Found</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                            
                                            {pagination.totalRecords > pagination.recordsPerPage &&
                                                <Box py={3} display="flex" justifyContent="center">
                                                    <Pagination
                                                        //count={totalPages}
                                                        key={page}
                                                        //count={ totalPages || 0 }
                                                        count={pagination.totalPages ? pagination.totalPages : "" || 0 }
                                                        color="secondary"
                                                        variant="outlined"
                                                        onChange={(e, value) => setPage(value)}
                                                        defaultPage={page}
                                                    />
                                                    
                                                </Box>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <CustomModal
                key = {key}
                show = {modalDetail.show}
                backdrop = "static"
                showCloseBtn= {true}
                isRightSideModal={true}
                child = {
                    <View selectedUserId={selectedUserId}/>
                }
                size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllUsers: bindActionCreators(getAllUsers, dispatch),
    getUserDetails: bindActionCreators(getUserDetails, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(User);