import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CustomModal from "../../components/shared/CustomModal";
import AddEnterprises from './add';
import View from './view';
import Edit from './edit';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { getAllEnterprises, updateExpireEnterprises } from '../../actions/enterprises';

const Enterprises = (props) => {
    document.title = "Enterprises";
    const [enterprises, setEnterprises] = useState([]);
    const [selectedEnterprises, setSelectedEnterprises] = useState({});
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState("");
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    //close Modal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    
    // get all enterprises
    const getEnterprises = (clearSearch = false) => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: 10,
        }

        setLoading(true);
        props.getAllEnterprises(params, (res) => {
            setLoading(false);
            if (res.status) {
                let enterprises = res.data.users
                setEnterprises(enterprises);
                setTotalPages(res.data.total_pages)
            }
            else {
                toast.error(res.message);
                setEnterprises("")
                setTotalPages("")
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    // set selected enterprises by id
    const editEnterprises = (enterprisesId) => {
        let enterprise = enterprises.find(item => item.id == enterprisesId);
        setSelectedEnterprises(enterprise);

        setTimeout(() => {
            setModalDetail({ show: true, flag: 'edit', title: 'Edit Enterprises' });
            setKey(Math.random());
        }, 200);
    }

    // const viewEnterprises = (enterprisesId) => {
    //     let enterprise = enterprises.find(item => item.id == enterprisesId);
    //     setSelectedEnterprises(enterprise);

    //     setTimeout(() => {
    //         setModalDetail({ show: true, flag: 'view', title: 'View Enterprises' });
    //         setKey(Math.random());
    //     }, 200);
    // }

    // marks as Expire Enterprises 
    const marksAsExpireEnterprises = (enterprisesId, enterprises) => {


        Swal.fire({
            title: 'Are you sure want to expire enterprises?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    is_active: enterprises.is_active == true ? false : true,
                    selectedEnterPrisesId: enterprisesId ? enterprisesId : "",
                    token: props.auth.token,
                }
                props.updateExpireEnterprises(params, res => {
                    setLoading(false);
                    if (res.statusCode == 200 || res.statusCode == 201) {
                        getEnterprises();
                        toast.success(res.message);
                    }
                    else {
                        if (res.statusCode !== 404) {
                            toast.error(res.message);
                        }
                    }
                },
                    (err) => {
                        setLoading(false);
                        toast.error(err.message);
                    })

            } else if (result.isDenied) {
                // 
            }
        })
    };

    useEffect(() => {
        getEnterprises();
    }, [page])

    return (
        <>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12 col-md-12">
                                <h1 className="m-0">Enterprises &nbsp;&nbsp;
                                    <button type="button" className="btn btn-primary float-right add-new-btn"
                                        onClick={() => { setModalDetail({ show: true, flag: 'add', title: 'Add Enterprises' }); setKey(Math.random()); }}
                                    >
                                        <i className="fas fa-plus"></i> Add Enterprise
                                    </button>&nbsp;&nbsp;

                                    {/* <button type="button" className="btn btn-primary add-new-btn upload"
                                        onClick={() => { setModalDetail({ show: true, flag: 'upload enterprise', title: 'Upload Enterprises' }); setKey(Math.random()); }}
                                    >
                                        <i className="fas fa-plus"></i> Upload Enterprise
                                    </button>&nbsp;&nbsp; */}

                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    {loading ?
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12 text-center pt-5 pb-5">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                        </>
                                        : (
                                            <>
                                                <table style={{ width: '100%' }} className="table table-striped">
                                                    <thead style={{ fontSize: '15px' }}>
                                                        <tr>
                                                            <th style={{ borderTop: '0px' }} className='pl-3'>Sr. No</th>
                                                            <th style={{ borderTop: '0px' }} className='pl-3'>Name</th>
                                                            <th style={{ borderTop: '0px' }} className='pl-3'>Allow Users Count</th>
                                                            <th style={{ borderTop: '0px' }} className='pl-3'>Expiry Date</th>
                                                            <th style={{ borderTop: '0px' }} className='pl-3'>Subscription Code</th>
                                                            <th style={{ borderTop: '0px' }} className='pl-3'>Status</th>
                                                            <th style={{ borderTop: '0px' }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ fontSize: '15px' }}>
                                                        {enterprises.length > 0 ?
                                                            <>
                                                                {enterprises.map((enterprises, index) =>
                                                                    <tr key={index} >
                                                                        <td className='pl-3'>{(page - 1) * 10 + index + 1}</td>
                                                                        <td>{enterprises.name.length > 50 ? enterprises.name.substring(0, 50) + " ..." : enterprises.name}</td>
                                                                        <td>{enterprises.allow_users_count}</td>
                                                                        <td>{enterprises.expiry_date}</td>
                                                                        <td>{enterprises.subscription_code.length > 50 ? enterprises.subscription_code.substring(0, 50) + "..." : enterprises.subscription_code}</td>
                                                                        <td>{enterprises.is_active == true ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: '#ff0000bf' }}>In-active</span>}</td>
                                                                        <td>
                                                                            {/* <button 
                                                                                className='btn btn-sm btn-del-key internal mr-2' 
                                                                                onClick={() => { viewEnterprises(1)}}
                                                                                style={{ background: '#17a2b8', color: 'white' }}
                                                                                >
                                                                                <span><i className='fa fa-eye'></i>&nbsp;</span>
                                                                            </button> */}
                                                                            <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                onClick={() => { editEnterprises(enterprises.id) }}
                                                                                style={{ background: '#17a2b8', color: 'white' }}>
                                                                                <span>
                                                                                    <i className='fas fa-pencil-alt'></i>&nbsp;
                                                                                </span>
                                                                            </button>

                                                                            {enterprises.is_active == true ?

                                                                                <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                    onClick={() => { marksAsExpireEnterprises(enterprises.id, enterprises) }}
                                                                                    style={{ background: '#ff0000bf', color: 'white' }}>
                                                                                    <span>
                                                                                        Disable
                                                                                    </span>
                                                                                </button> : <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                    onClick={() => { marksAsExpireEnterprises(enterprises.id, enterprises) }}
                                                                                    style={{ background: '#1e831e', color: 'white' }}>
                                                                                    <span>
                                                                                        Enable
                                                                                    </span>
                                                                                </button>
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <tr>
                                                                    <td colSpan='4' style={{ textAlign: 'center' }}>No Data Found</td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </tbody>
                                                </table>
                                                <Box py={3} display="flex" justifyContent="center">
                                                    <Pagination
                                                        key={page}
                                                        //count={totalPages}
                                                        count={totalPages || 0}
                                                        color="secondary"
                                                        variant="outlined"
                                                        onChange={(e, value) => setPage(value)}
                                                        defaultPage={page}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                ids={modalDetail.flag == "uploadenterprise" ? 'uploadenterprisemodal' : ''}
                isRightSideModal={true}
                child={modalDetail.flag == 'add'
                    ? <AddEnterprises closeModal={() => handleOnCloseModal()} refreshEnterprises={() => { getEnterprises() }} />
                    : modalDetail.flag == 'view'
                        ? <View closeModal={() => handleOnCloseModal()} />
                        : modalDetail.flag == 'edit'
                            ? <Edit closeModal={() => handleOnCloseModal()} refreshEnterprises={() => { getEnterprises() }} selectedEnterprises={selectedEnterprises} />
                            :
                            ""
                }
                size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />
        </>

    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllEnterprises: bindActionCreators(getAllEnterprises, dispatch),
    updateExpireEnterprises: bindActionCreators(updateExpireEnterprises, dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(Enterprises);