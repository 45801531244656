import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { updateResources, uploadPhoto } from '../../actions/resources';
import UploadIconImage from "../../public/images/upload-icon.svg";
import groupImage from "../../public/images/Group 10825.svg";
import Button from 'react-bootstrap/Button';
import { MultiSelect } from "react-multi-select-component";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const EditResources = (props) => {
    const tags = props.tags;
    const selectedResources = props.selectedResources;
    const [content, setContent] = useState(selectedResources ? selectedResources.content : "");
    const [title, setTitle] = useState(selectedResources ? selectedResources.title : "");
    const [image, setImage] = useState(selectedResources ? selectedResources.thumbnail_url : "");
    const [tagsToOptions, setTagsToOptions] = useState(
        tags && tags.length > 0 ? tags.map(tags => tags) : []
    );
    const [selectedTags, setSelectedTags] = useState(
        selectedResources ? selectedResources.post_tag?.map((tags, index) => {
            let tagName = tagsToOptions && tagsToOptions.length > 0 ? tagsToOptions.find((tagsOptions) => tagsOptions.value === tags.tag_id) : [];
            return {
                value: tags.tag_id,
                label: tagName && tagName.label,
            }
        }) : []);
    const [loading, setLoading] = useState(false);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [categoryId, setCategoryId] = useState(selectedResources.category ? selectedResources.category.id : "");


    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "content") {
            setContent(value.trimStart());
        }
        else if (fieldName === "title") {
            setTitle(value.trimStart());
        }
        else if (fieldName === "Category") {
            setCategoryId(value.trimStart());
        }
    };

    const selectedTagId = selectedTags && selectedTags.length > 0 ? selectedTags.map(item => item.value) : [];

    //edit resources
    const handleUpdate = () => {
        if (!title) {
            toast.error("Please enter title");
            return;
        }
        else if (!categoryId) {
            toast.error("Please select category");
            return;
        }
        else if (selectedTags.length <= 0) {
            toast.error('Please select atleast one tag.');
            return false;
        }
        else if (!content) {
            toast.error("Please enter content");
            return;
        }


        let params = {
            token: props.auth.token,
            content: content,
            title: title,
            category_id: categoryId,
            // thumbnail_url: image,
            tags: selectedTagId,
            selectedResourcesId: selectedResources ? selectedResources.id : "",
        }

        setLoading(true);
        props.updateResources(params, res => {
            setLoading(false);
            if (res.status) {
                toast.success(res.message);
                props.refreshResources();
                props.closeModal();
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })

    };

    const onChangePicture = e => {
        const imageFile = e.target.files[0];
        if (!imageFile) {
            toast.error("Please select an image.");
            return false;
        }
        else if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
            toast.error("Please select a valid image.");
            return false;
        }

        let params = {
            file: imageFile
        }

        setIsLoadingImg(true);
        props.uploadPhoto(params, res => {
            setIsLoadingImg(false);
            if (res.status) {
                let picUrl = res.data.post_photo;
                setImage(picUrl);
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setIsLoadingImg(false);
                toast.error("Something went wrong!");
            });

    };

    const customMultiSelectItemRenderer = ({ checked, option, onClick, disabled }) => {
        return (
            <div className={`item-renderer ${disabled ? "disabled" : ""}`}>
                <input
                    type="checkbox"
                    onChange={onClick}
                    checked={checked}
                    tabIndex={-1}
                    disabled={disabled}
                />
                <span>{option.label}</span>
            </div>

        );
    };

    // const customMultiSelectValueRenderer = (selected, _options) => {
    //     return selected.length
    //       ? selected.map(({ value }, index) => {return selected.length > (index + 1) ? value +', ' : value})
    //       : "Select";
    // };



    useEffect(() => {
        //
    }, [])

    return (
        <>
            <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
                {/* <div className="form-group mt-3 mb-3 col-md-12  text-center">
                        <div className="profile_upload">
                            {isLoadingImg ? 
                                <div className="spinner-border text-success mt-3 loader_" role="status" >
                                    <span className="sr-only" >Loading...</span>
                                </div> 
                                :
                                <div className="inputfile-box">
                                    <input type="file" id="file" accept="image/*" className="form-control d-none" onChange={(e) => {onChangePicture(e)}} required="" /> 
                                    <label htmlFor="file">
                                    <span className="file-button">
                                        <img src={image ? image : groupImage} className="upload_src" alt=' ' />
                                        <img src={UploadIconImage} className="upload_icon" alt=' ' />
                                    </span>
                                    </label>
                                </div>
                            }
                        </div>
                    </div> */}

                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="inputFirstName">Title</label>


                    <input className="form-control" id="inputName" type="title" value={title} placeholder="Title"
                        name="title" required="" onChange={(e) => { onChangeInput(e, 'title') }} />
                </div>

                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="">Category</label>

                    <select className="form-control cust_spc" aria-label="Default select example" onChange={(e) => { onChangeInput(e, 'Category') }} value={categoryId}>
                        <option value="">Select Category</option>
                        {props.categoriesToOptions && props.categoriesToOptions.length > 0 && props.categoriesToOptions.map((frequency, index) => {
                            return <option value={frequency.value} key={index}>{frequency.label}</option>
                        })}
                    </select>
                </div>
                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="">Tags</label>
                    {/* <MultiSelect
                        className="react-multiselect"
                        options={tagsToOptions}
                        value={selectedTags && selectedTags.length > 0 ? selectedTags : []}
                        onChange={setSelectedTags}
                        labelledBy="Select"
                        ItemRenderer={customMultiSelectItemRenderer}
                    //valueRenderer={customMultiSelectValueRenderer}
                    /> */}
                    <Select
                        isMulti
                        name="tags"
                        options={tagsToOptions}
                        value={selectedTags && selectedTags.length > 0 ? selectedTags : []}
                        onChange={setSelectedTags}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Tags"
                    />
                </div>
                <div className="form-group mt-3 mb-3 col-md-12">
                    <label className="label_text" htmlFor="inputFirstName">Content</label>
                    {/* <input className="form-control" id="inputName" type="content" value={content} placeholder="content"
                            name="content" required="" onChange={(e) => {onChangeInput(e, 'content')}} /> */}
                    <CKEditor
                        name="content"
                        id="inputName"
                        placeholder="content"
                        editor={ClassicEditor}
                        data={content}
                        // onReady={(editor) => {
                        //     // You can store the "editor" and use when it is needed.
                        //     console.log("Editor is ready to use!", editor);
                        // }}
                        onChange={(event, editor) => {
                            const data = editor.getData()
                            setContent(data)
                            // console.log({ event, editor, data }, 'editor');
                        }

                        }
                    />

                </div>
                <div className="form-group text-center col-md-12 mt-3 mb-3">
                    <button className="btn btn-secondary" type="button" onClick={() => { props.closeModal() }}>
                        <span>Close</span>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={loading} type="button" onClick={() => { handleUpdate() }}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Save Changes</span>
                    </button>
                </div>
            </form>

        </>
    )
}


const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    updateResources: bindActionCreators(updateResources, dispatch),
    uploadPhoto: bindActionCreators(uploadPhoto, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditResources);
